<template>
  <div>
    <v-container
      v-if="gameStatus && gameStatus.finished_at"
      class="d-flex align-center flex-wrap flex-column">

      <h2 class="title-post" v-html="'Prueba finalizada'"/>


      <span v-if="gameStatus.points !=null" v-html="`Puntos obtenidos: <strong> ${gameStatus.points}</strong>`"> </span>
      <span v-if="gameStatus.time !=null" v-html="`Tiempo empleado: <strong> ${getTimeForHumansString}</strong>`"> </span>
      <span v-if="gameStatus.finished_at !=null"
            v-html="`Fecha de finalización: <strong>${dateHelper.getDateTimeStringLocale(gameStatus.finished_at, true)}</strong>`"> </span>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "gameStatus",
  props: {
    gameStatus: {
      type: Object | null
    }
  },
  computed: {
    getTimeForHumansString() {
      if (!isNaN(this.gameStatus?.time)){
        const data = this.dateHelper.getTimeForHumans(this.gameStatus.time);

        let result = data.days ? `${data.days} ${ this.$tc('common.time.days', data.days) }, ` : '';
        result += data.hours ? `${data.hours} ${ this.$tc('common.time.hours', data.hours) }, ` : '';
        result += data.minutes ? `${data.minutes} ${ this.$tc('common.time.minutes', data.minutes) }, ` : '';

        result += `${data.seconds} ${ this.$tc('common.time.seconds', data.seconds) }`;

        return result;
      }

    }
  }
}
</script>

<style scoped>

</style>
